import React,{useState, useEffect} from 'react'
import {Modal,Row,Col,Form} from 'react-bootstrap'
import {InText, InDetail} from '../Shared/InputControl'
import OptionsControl from '../Shared/OptionsControl'
import {RoleMembers} from '../Role/RoleMembers'
import { addDays } from '../Shared/Formating'
import {ModalHeader, ModalFooter} from '../Shared/ModalControl'
import {OnSubmit} from '../Shared/ActionControl'
import {Fetch} from '../Api'

const CourseModal = ({global, action, onHide, Fetched, Message}) => {
  const entity = "Course"
  const readOnly = (action.crud ?? 2) === 2
  const [loaded, Load] = useState(false)
  const [item, Item]= useState({Id:0, Manager:1, Name:'الدورة', Description:'',StartDate:addDays(0), EndDate:addDays(30), LevelId:1})

      useEffect(() => {
        if(action.id>0) {
            global.GetItem(entity, action.id)
            .then(data => {
                Item(data)
                Load(true)      
            })
            .catch(error => {
              Message(error)
              console.log({ error })
            })
        }
        else if(action.crud===1)
        {
          Load(true)
        }
    }, [loaded])
 
            
    const onChange = e => {
        e.preventDefault()
        Item({...item, [e.target.name]: e.target.value})
        //  console.log(entity+'Modal onChange',e.target.value,item)
      }

    const onFetch = (e) => {
        //console.log(entity+'Modal onFetch',item,action)
        Fetch(entity, item, action.crud, Load, Message)
        Fetched(true)
    }

        return(
            <div className="container">

            <Modal
            show={action.show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            
            <ModalHeader title={entity} id={action.id} />

            <Modal.Body>

 <Row>
     <Col sm={11} >
         {
            !loaded ? <div>{entity} is loading...</div> :
         <Form onSubmit={onFetch}>

<InText name="Name" value={item?.Name ?? ''} onChange={onChange} readOnly={readOnly} label={"الإسم"} required />
<OptionsControl entity={'Level'} id={item?.LevelId ?? 1} onChange={onChange} readOnly={readOnly} label='المستوى'/> 

<InDetail name="Description" value={item?.Description ?? ''} onChange={onChange} readOnly={readOnly} label={"ملاحظات"} />

<RoleMembers role="Manager" global={global} id={item?.Manager ?? 1} onChange={onChange} readOnly={readOnly} label='مديرة' entityId={action.id} entity={entity} Message={Message} /> 

<Form.Group controlId="CourseDate">
  <Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>تاريخ الدورة</Form.Label>

  <Form.Control
    type="date"
    name="StartDate"
    defaultValue={global.toString(item?.StartDate)}
    readOnly={readOnly}
    onChange={onChange}
  />  
  <Form.Control
    type="date"
    name="EndDate"
    defaultValue={global.toString(item?.EndDate)}
    readOnly={readOnly}
    onChange={onChange}
  />
</Form.Group>

<OnSubmit crud={action.crud} />

         </Form>
        }
     </Col>
 </Row>

            </Modal.Body>
            <ModalFooter onHide={onHide} />
          </Modal>
</div>  
    )
}

export default CourseModal
