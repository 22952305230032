import React,{useState,useEffect} from 'react';
import './Member.css';
import {Table} from 'react-bootstrap';
import {Container, Row, FormControl} from 'react-bootstrap';
import {MemberModal} from './MemberModal';
import CourseModal from '../Course/CourseModal'
import {ToDate, ToClick} from '../Shared/ViewControl'
import {ToNotify} from '../Shared/ViewControl'
import {OnAction, OnExport} from '../Shared/ActionControl'
import Api, {Get, Fetch} from '../Api'

const Registering = ({global}) =>{
    const [tab, Active] = useState("Registered")

    const [message, Message] = useState('')
    const [member, Member] = useState({id:0, crud:0, show:false})
    const [course, Course] = useState({id:0, crud:0, show:false})

    useEffect(()=>{
        sessionStorage.setItem("routeCurrent","/registering")
        //let rc=sessionStorage.getItem("routeCurrent"); alert(rc);
    },[])
 
    return (
        <Container>
        <div className="Tabs">
            <ul className="TabsNav">
            <li className={tab === "Registered" ? "active" : ""} onClick={()=>Active("Registered")}>Registered</li>
            <li className={tab === "Accepted" ? "active" : ""} onClick={()=>Active("Accepted")}>Accepted</li>
            <li className={tab === "Blocked" ? "active" : ""} onClick={()=>Active("Blocked")}>Blocked</li>
            <li className={tab === "Deleted" ? "active" : ""} onClick={()=>Active("Deleted")}>Deleted</li>
            </ul>
            <Register tab={tab} Member={Member} Course={Course} Message={Message} /> 
        </div>  

{member.show ?
        <MemberModal
        global={global}
        id={member.id}
        mode={member.crud}
        show={true}
        onHide={() => Member({show:false, id:0, crud:0})}
        />
: null }


<ToNotify message={message} onClose={()=>Message('')} />


{course.show ?
        <CourseModal
        global={global}
        action={course}
        onHide={() => Course({show:false, id:0, crud:0})}
        Message={Message}
        />
: null}

</Container>  

    )
}


const Register = ({tab, Member, Course, Message}) =>{
    const entity='Registering'
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])

    const [search, Search]  = useState("")
    const [data, Data] = useState(null);

    useEffect(() => {
        //alert(request)
        Data(null)
        Items([])
        Get(entity+"/"+tab, Items, Load, Message)
     }, [tab, loaded])   
     
    const onFetch = (item,crud) => {
        Fetch(entity, item, crud, Load, Message)
    } 

    const onChange = (e) => {
        e.preventDefault()
        Search(e.target.value)
    }

    let filteredItems = items.filter(
        (item)=>{
            return (item.Name+item.Phone+item.Country+item.Course+item.Description).indexOf(search)!==-1;
        }
    )

    const onExport = () => {

        let request="Export/Registering"+"/"+tab
 
        Api.get(request).then(response => {

            Data(response.data)

            //console.log('Api.Get', request, response)
        })
        .catch(error => {
            Message && Message(error)
        })
    } 


    return (

<div style={{overflow:"scroll", width:"97%", margin:"1%", direction:"rtl", textAlign:"right"}}>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>
            <FormControl type="text" placeholder="الإسم" value={search} onChange={onChange} />
        </th>
        <th>البلد</th>
        <th>الهاتف</th>
        <th>البريد</th>
        <th>تقديم</th>
        <th>
            <FormControl type="text" placeholder="الدورة" value={search} onChange={onChange} />
        </th>
        <th>المديرة</th>
        <th>تقديم</th>
        <th>تاريخ</th>
{ tab==="Registered" ?
        <th style={{ width:"5rem" }}>            
            <OnAction onCreate={()=> Member({show:true, id:0, crud:1})} rstyle={{justifyContent: 'center'}} />
        </th>
: 
<th style={{ width:"5rem" }}>            
    <OnExport onFetch={onExport} data={data} name={'Registered'+tab+'.csv'} />
</th>
}        
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        filteredItems.map(item=>
            <tr key={tab+item.Id}>


<ToClick value={item.MemberId} text={item.Name} onClick={()=> Member({show:true, id:item.MemberId, crud:2})} />
<td>{item.Country}</td>
<td>{item.Phone}</td>
<td>{item.Email}</td>
<td>{item.Infos}</td>
<ToClick value={item.CourseId} text={item.Course} onClick={()=> Course({show:true, id:item.CourseId, crud:2})} />
<td>{item.Manager}</td>
<td>{item.Description}</td>
<ToDate value={item.updatedAt} />
{ tab==="Registered" ?
<td>
<OnAction onValid={()=>onFetch(item, 3)} onBlock={()=>onFetch(item, 4)} />
</td>
: null}
            </tr>
            )
    }
</tbody>
</Table>
</div>

        )
}


export {Registering}

/*
            <CSVDownload data={data} target="_blank" />
https://stackoverflow.com/questions/53504924/reactjs-download-csv-file-on-button-click
 */