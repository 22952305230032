import React,{useEffect, useState} from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import './Planning.css'
import {OnAction} from '../Shared/ActionControl'
import { dayName, viewDate, Duration, toText, isEditable } from '../Shared/Formating'

import {Get, Fetch} from '../Api'

const PlanControl = ({entity, date, Message, onAction, onPrev, onNext, search}) => {

    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
    const [action, Action] = useState({id:0, crud:0, show:false})
    let tstyle = { position:'absolute', width: '10%', height: '5%', fontWeight:'bold', color:'darkred' }
    
    useEffect(() => {
        //alert(date)
        if(date && date.length>0) {
            Get(`${entity}/${date}`, Items, Load, Message)

            if(action?.id>0) 
                onSelect(action.id)
            else if(items?.length>0) 
                onSelect(items[0].Id)
        }
    }, [loaded, date])

    const onSelect =(id) => {
        //alert(id)
        Action({...action, id:id})
        onAction && onAction({...action, id:id})
    }

    const onModal=(item, crud=3) => {
        //alert(item.Id)
        onAction && onAction({id:item.Id, crud:crud, show:true})
    }
        
    const Create = () => {
        onAction && onAction({id:0, crud:1, show:true})
/*
        let item={
            Description:'New '
        }
        onFetch(item, 1)
*/
    }   

    const onUpdate=(item) => {
        onFetch(item, 3)
    }

    const onDelete=(item)=> {
        onFetch(item, 4)
    }

    const onFetch = (item,crud) => {
        Fetch(entity, item, crud, Load, Message)
    }    

    let filteredItems = items.filter(
        (item)=>{
            return (item.Id+item.Course+item.Lesson+item.Team+item.Description+item.Master+item.Date)
            .indexOf(search)!==-1;
        }   
    )

    return (


<div style={{overflow:"scroll", width:"97%", margin:"1%", direction:"rtl", textAlign:"right"}}>
{
loaded 
? 
<div className="plan-grid">
<OnAction onCreate={()=> Create()} onRefresh={()=>Load(false)} title={'Plan '+date} />
    {
        filteredItems.map(item=>

            <Plan key={`paln-${item.Id}`} item={item} selected={action.id} 
            onSelect={()=>onSelect(item.Id)} onSave={onUpdate} onDelete={onDelete} onAction={onModal}
            fstyle={{backgroundColor:'#606060'}} />
        )
    }

    <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '20%', top:'40%', left:'1%' }}
            onClick={onNext}
            ><i className="fa fa-arrow-left" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

        <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '20%', top:'40%', left:'94%' }}
            onClick={onPrev}
            ><i className="fa fa-arrow-right" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

        <div style={{ ...tstyle, top:'90%', left:'90%' }}>
            <p>{date}</p>
        </div>

</div>
: 
<h6>{entity}(s) loading...</h6>

}

</div>

        )
}

const Plan = ({item, onSelect, selected, onSave, onDelete, onAction, fstyle=null}) => {
    const thisDay = ((new Date(item.Date)).getDate()===(new Date()).getDate()) 
    const [editing, Edit] = useState({Id:0, Text:''});
    const [editable, Editable]=useState(false)

    //console.log('<Plan...', item)
    let bsize = thisDay ? "2px solid" : 0
    let cstyle= {...(fstyle??{opacity:1 }), border:{bsize}  }

    const onClick = (item) => {
        onSelect(item.Id)
    }

    const onEdit = (item) => {
        Edit({Id:item.Id, Text:item.Text})
        onAction && onAction(item)
        //console.log('Comments.onEdit', editing.Text, item.Text)
    }

    const onCancel=()=> {
        //Edit({Id:0,Text:''})  
        onSelect(0)  
    }

    useEffect(()=>{

        Editable(isEditable(item.updatedAt))
        if(selected===0)
        {
            Edit({Id:0, Text:item.Text})
        }
        //console.log('Notes.useEffect', selected, editable, editing)
    },[selected, editable])

/*
<th>رقم    معلمة    مجموعة    دورة    درس    يوم    ساعة    مدة    ملاحظات    تاريخ</th>
*/    
    return (
        <Col className="plan-cell" style={cstyle} >

        <Row className="align-items-end" onClick={()=>onClick(item)}>
        <Col sm={3} style={{color:'gold' }} >{dayName(item.Date)}</Col>
        <Col sm={3} style={{fontWeight: 'bold', color:'silver' }} >{item.Master}</Col>
        <Col sm={3} style={{fontWeight: 'bold', color:'gold' }} >{item.Team}</Col>
        <Col sm={3} style={{color:'silver' }} >{item?.Course}</Col>
        </Row>
        <Row style={{ margin: '0.3rem', color:'blue', backgroundColor:'white', zoom:'110%' }}>
<Col sm={6} style={{overflowY: 'scroll', color:'cyan', direction:'ltr' }} >{toText(item.Description)}</Col>
<Col sm={3} style={{fontWeight: 'bold', color:'silver' }} >{Duration(item.Duration)}</Col>
<Col sm={3} style={{fontWeight: 'bold', color:'silver' }} >{viewDate(item.Date)}</Col>
</Row>        
{ selected===item.Id ?
<>
            { editing?.Id>0 ?
                <OnAction onSave={()=> onSave(editing)} onCancel={onCancel} />
            : 
            <>
            { editable ?
                <OnAction onEdit={()=>onEdit(item)} onPerform={()=>onAction(item,9)} onDelete={()=>onDelete(item)}/>  
            : null }
            
            </>
            }
</>
: null }

    </Col> 
    )
}

export {PlanControl}


/*
{ !modal ?
    <Button className="mr-2" variant={"outline-info"}  
        style={{ position:'absolute', width: '6%', aspectRatio:'1 / 1', top:0, left:0, margin:'6px' }}
            onClick={()=>Expand(true)}
            ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}></i>
            </Button>    
: null }
ç!*/