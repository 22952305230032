import React,{useState, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import {Get} from '../Api'

const OptionsControl = ({entity, id, label, onChange, readOnly=false}) => {

    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])

    useEffect(() => {
        Get(`Setting/${entity}`, Items, Load)
     }, [loaded])       

    const onSelect=(event) =>{
        if(event?.target) {
            onChange && onChange(event)
        }
    }
 
    return (
        loaded ? 
        <Form.Group className='form-row'>
            
            <Form.Control as="select" name={entity+"Id"} className="col-sm-9 text-right" onChange={onSelect} defaultValue={id} readOnly={readOnly} disabled={readOnly} >
                {
                    items.map(item => 
                    <option key={item.Id} value={item.Id}>{item?.Value ?? item.Name}</option>
                    )
                }
            </Form.Control>
        
            <Form.Label className='col-sm-3' align='right'>:{label ?? entity}
            </Form.Label>
        </Form.Group> 
        
        : <div>{entity}s is loading...</div> 
    )
}

export default OptionsControl