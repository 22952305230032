import React from 'react'
import {Modal, Button} from 'react-bootstrap';

export const ModalHeader = ({title, id=0, message=null}) => {

    return (
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">            
            { id && id>0 ?
                <h3>{title} - Id={id}</h3>  
            :   
                <h3>{title}</h3>          
            }
            { message && message.length>0 ?
                <h5>{message}</h5>
            : null}
            </Modal.Title>
        </Modal.Header>
    )
}

export const ModalFooter = ({label, onHide}) => {

    return (
        <Modal.Footer>
            <Button variant="danger" size="sm" onClick={onHide}><i className="fa fa-close"></i> {label??"إلغاء"}</Button>
        </Modal.Footer>
    )
}
